<template>
  <div class="forgot-password">
    <div class="container-s">
      <div class="row">
        <div class="col col-forgot-password-text">
          <H2 :title="h2.title" :color="h2.color" :id="h2.id"/>
          <p class="need-help-text" v-html="cms.page.fgNeedHelp"></p>
        </div>

        <div class="col">
          <form class="form-forgot">
            <Input
                type="text"
                :label_white="true"
                v-model="emailAddress"
                :error="v$.emailAddress"
                v-on:keyup.enter="formSubmit"
            >
              {{ cms.page.fgEmailAddress }} <span class="star">*</span>
            </Input>

            <div class="error-row">
              <p v-if="formErrors" class="register-error">{{ cms.page.fgErrorsInForm }}</p>
              <div class="" v-if="registerError.length > 0" v-for="error in registerError">
                <p class="register-error">{{ error }}</p>
              </div>
            </div>

            <div class="form-btn">
              <button
                  type="button"
                  class="btn btn-default"
                  v-bind:class="{ 'form-actively-submitting': formActivelySubmitting }"
                  @click="formSubmit"
              >
                {{ cms.page.fgSubmit }}
              </button>
            </div>
          </form>

          <div :class="{ show: isSuccess }" class="success-holder">
            <h5>
              {{ cms.page.fgSuccess }}
            </h5>
            <p>
              {{ cms.page.fgBeOnTheLookOut }}
            </p>
            <div class="success-ico">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 101 101">
                <path
                    d="M50.63 0C22.666 0 0 22.667 0 50.63a50.63 50.63 0 0 0 50.63 50.628c27.961 0 50.628-22.667 50.628-50.629A50.63 50.63 0 0 0 50.63 0zm0 9.8c22.564 0 40.83 18.26 40.83 40.83a40.808 40.808 0 0 1-40.83 40.83A40.808 40.808 0 0 1 9.8 50.63C9.8 28.063 28.06 9.8 50.63 9.8zm28.622 26.593l-4.6-4.638a2.45 2.45 0 0 0-3.465-.013L42.33 60.367 30.123 48.06a2.447 2.447 0 0 0-3.464-.014l-4.638 4.6a2.449 2.449 0 0 0-.014 3.465L40.54 74.795a2.447 2.447 0 0 0 3.464.014l35.234-34.951a2.448 2.448 0 0 0 .014-3.465z"
                    fill="#fff"
                    fill-rule="evenodd"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Input from "@/components/Input";
import H2 from "../components/H2";
import {mapStores} from "pinia";
import {useAuthStore} from "@/stores/authStore"
import {useCmsStore} from "@/stores/cmsStore";
import {useDesignStore} from "@/stores/designStore";
import useVuelidate from "@vuelidate/core";
import {email, helpers, maxLength, minLength, minValue, numeric, required, requiredIf,} from '@vuelidate/validators'

export default {
  name: "ForgotPassword",
  components: {
    H2,
    Input,
  },
  setup: () => ({v$: useVuelidate({$lazy: false, $autoDirty: true})}),
  data() {
    return {
      cms: {
        loaded: false,  // set to 'true' when CMS content has been returned from API
        options: {
          Common: true,
          Components: [],
          LanguageCode: "",
          PageName: "forgot_password",
          MinistryUpdates: false
        },
        page: {},
      },
      formActivelySubmitting: false,
      formErrors: false,
      formSubmittedSuccessfully: false,
      registerError: [],
      emailAddress: '',
      h2: {
        title: "We Are Here To Help.",
        id: "t1",
        color: "color-black",
      },
      isSuccess: false,
    };
  },
  computed: {
    ...mapStores(useAuthStore, useCmsStore, useDesignStore),
  },
  mounted() {
    this.setTitlePageFromCms()
  },
  async created() {
    this.setContentLoaded(false);
    this.resetErrorsAndMessaging()
    await this.fetchCmsData();
  },
  methods: {
    async fetchCmsData() {
      try {
        await this.cmsStore.getPageAndOrCommonAndOrComponents(this.cms.options)
            .then(results => {
              if (results) {
                this.cms.page = results
              } else return false

              this.setAllCmsContent(results)
              this.setContentLoaded(true)
              return true;
            })
      } catch (e) {
        console.error(e)
      }
    },
    setAllCmsComponentContent(componentContents) {
      this.cms.comp = componentContents;
    },
    async formSubmit() {
      if (this.formActivelySubmitting) return false

      this.resetErrorsAndMessaging()
      this.setFormBeingActivelySubmitted(true)

      let loader = null
      const isFormValid = await this.v$.$validate()
      try {
        loader = this.$loading.show()
        if (isFormValid) {
          const response = await this.authStore.forgotPassword(this.emailAddress)
          await this.handleServerResponse(response)
        } else {
          this.setFormErrorMessage(true)
          return false
        }
      } catch (e) {
        console.error('Error in ForgotPassword.vue', e)
      } finally {
        loader.hide()
        this.setFormBeingActivelySubmitted(false)
      }
    },
    async handleServerResponse(res) {
      let errMessage = "There was an issue resetting your password, please try again."

      if (res && typeof (res) === 'string') {
        if (res === "Reset password email sent") { // success
          this.successfulPasswordReset()
        }
        errMessage = res
      } else if (res && Array.isArray(res)) {
        this.setFormErrorMessage(true)
        return this.setRegisterErrorMessage(res)
      }
      this.setRegisterErrorMessage([errMessage])
    },
    setFormBeingActivelySubmitted(status) {
      this.formActivelySubmitting = status
    },
    setFormErrorMessage(status) {
      this.formErrors = status
    },
    setRegisterErrorMessage(message) {
      this.registerError = []
      if (message) {
        message.map(msg => this.registerError.push(msg))
      }
    },
    resetErrorsAndMessaging() {
      this.setFormErrorMessage(false) // reset form error message
      this.setRegisterErrorMessage() // reset register error message
      this.isSuccess = false
    },
    setTitlePageFromCms(page) {
      this.h2.title = page && page.fgWeAreToHelp ? page.fgWeAreToHelp : 'We Are Here To Help.'
      const pageTitle = page && page.fgTitle ? page.fgTitle : 'Forgot Password'

      this.designStore.setTitlePage({
        title: pageTitle,
        sub_title: "",
        img_class: "hero-default-desktop",
        btn_history: false,
      });
    },
    setContentLoaded(loadedStatus) {
      this.cms.loaded = loadedStatus;
    },
    setAllCmsContent(cmsContent) {
      this.cms.page = cmsContent;
      this.setTitlePageFromCms(cmsContent)
    },
    successfulPasswordReset() {
      this.setFormBeingActivelySubmitted(false)
      this.setFormErrorMessage(false)
      this.setRegisterErrorMessage()
      this.isSuccess = true
    },
  },
  validations() {
    return {
      emailAddress: {
        required: helpers.withMessage('Email is required', required),
        maxLengthValue: helpers.withMessage('Email cannot be longer than 100 characters', maxLength(100)),
        email,
      },
    }
  }
};
</script>

<style scoped lang="scss">
.forgot-password {
  > .container-s {
    padding-right: 0;
  }

  .row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media screen and (max-width: $md) {
      flex-direction: column;
    }
  }

  .col {
    font-size: 18px;
    line-height: 1.5;
    max-width: 100%;
    position: relative;
    @media screen and (min-width: $md) {
      width: 100%;
    }
    @media screen and (max-width: $md) {
      width: 100%;
    }

    h2 {
      color: #0c2e3c;
    }

    p {
      max-width: 750px;
      font-size: 18px;
      line-height: 1.5;
      color: white;
      padding: 0 15px 0 0;
      @media screen and (max-width: $md) {
        padding: 25px 0;
      }
    }
  }

  .form-forgot {
    width: 100%;
    max-width: 701px;
    background-color: $blue;
    padding: 55px 50px;
    margin: 0 0 0 auto;
    @media screen and (max-width: $md) {
      padding: 30px 15px;
      max-width: 740px;
    }

    :deep {
      .input-container {
        .title-label {
          color: $white1;
        }
      }
    }

    .form-btn {
      margin-top: 30px;
      display: flex;
      justify-content: left;
      @media screen and (max-width: $md) {
        display: flex;
        justify-content: center;
      }
    }
  }

  .success-holder {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $blue;
    color: white;
    text-align: center;
    padding: 35px 50px;
    opacity: 0;
    z-index: -1;
    @media screen and (max-width: $lg) {
      padding: 30px 15px;
    }

    &.show {
      opacity: 1;
      z-index: 1;
    }

    p {
      margin: 15px 0 35px;
      padding: 0;
      @media screen and (max-width: $sm) {
        margin: 15px 0;
      }
    }

    .success-ico {
      max-width: 101px;
      max-height: 101px;
      margin: 0 auto;
      @media screen and (max-width: $md) {
        max-width: 75px;
        max-height: 75px;
      }
    }
  }

  .form-actively-submitting {
    cursor: not-allowed;
  }

  .input-error {
    color: $white1 !important;
    font-size: 18px;
    font-weight: 500;
  }

  .register-error {
    color: $white1 !important;
    font-size: 18px;
    font-weight: 500;
  }
}

div.col-forgot-password-text {
  padding-left: 50px;
  padding-right: 50px;
}

@media screen and (max-width: $sm) {
  div.container-s {
    padding-left: 0
  }

  div.col-forgot-password-text {
    padding: 20px;
  }
}

.container-s
:deep div.col p.need-help-text {
  color: #0c2e3c;
}
</style>
